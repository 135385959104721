import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTelegram,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import logo from "./logo.png";
import { height } from "@fortawesome/free-brands-svg-icons/fa42Group";
import { Carousel } from "flowbite-react";
function App() {
  const [siteList, setSiteList] = useState([]);
  const [bonusList, setBonusList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const slideInterval = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://link.redirect-links.xyz/api/site"
        );
        setSiteList(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching site data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://link.redirect-links.xyz/api/bonus"
        );
        setBonusList(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching site data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    startSlideTimer();
    return () => stopSlideTimer();
  }, [bonusList]);

  const startSlideTimer = () => {
    stopSlideTimer();
    slideInterval.current = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === bonusList.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);
  };

  const stopSlideTimer = () => {
    if (slideInterval.current) {
      clearInterval(slideInterval.current);
    }
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
    startSlideTimer();
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? bonusList.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === bonusList.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        <nav className="navbar flex flex-col lg:flex-row lg:justify-between p-5">
          <div className="flex gap-2 justify-center">
            <img
              src="https://ugc.production.linktr.ee/53416051-024c-43b4-bf1a-4a252c35b8c6_IMG-20240703-WA0021.jpeg?io=true&size=avatar-v3_0"
              alt="Yılmaz Vegas"
              style={{
                width: "75px",
                height: "75px",
                borderRadius: "100%",
              }}
            />
            <h1 className="pt-7 text-2xl font-bold">Yılmaz Vegas</h1>
          </div>

          <ul className="pt-7 flex flex-wrap justify-center gap-3">
            <li className="py-2 px-3 text-white rounded-md bg-[#2277ff]">
              <a href="https://t.me/yilmazvegasduyuru" className="flex gap-1">
                <FontAwesomeIcon icon={faTelegram} className="mt-1" />
                <span>Telegram</span>
              </a>
            </li>
            <li className="py-2 px-3 text-white rounded-md bg-[#ff22bb]">
              <a
                href="https://www.instagram.com/yilmazvegas"
                className="flex gap-1"
              >
                <FontAwesomeIcon icon={faInstagram} className="mt-1" />
                <span>Instagram</span>
              </a>
            </li>
            <li className="py-2 px-3 text-white rounded-md bg-[#000]">
              <a
                href="https://www.tiktok.com/@ylmazvegas2?_t=8nuymuht3ru&_r=1"
                className="flex gap-1"
              >
                <FontAwesomeIcon icon={faTiktok} className="mt-1" />
                <span>TikTok</span>
              </a>
            </li>
            <li className="py-2 px-3 text-white rounded-md bg-[#ff2222]">
              <a
                href="https://youtube.com/@yilmazvegas?si=fPNaFKy9a_MCLwNh"
                className="flex gap-1"
              >
                <FontAwesomeIcon icon={faYoutube} className="mt-1" />
                <span>YouTube</span>
              </a>
            </li>
          </ul>
        </nav>

        <div className="flex flex-col lg:flex-row lg:justify-between">
          
          <aside className="w-[95%] sm:w-[85%] lg:w-60 bg-gray-900 text-white flex flex-col items-center p-3 sm:p-4 rounded-xl sm:rounded-2xl mx-auto lg:mx-0 mb-6 sm:mb-8 lg:mb-0 lg:h-[80vh]">
            {bonusList[0] && (
              <>
                <div className="flex flex-col items-center mt-4 sm:mt-6 mb-6 sm:mb-8">
                  <img
                    src={bonusList[0].data.logo}
                    alt="Logo"
                    className="h-8 sm:h-10 mb-3 sm:mb-4"
                  />
                  <span className="bg-red-600 text-xs sm:text-sm px-2 py-1 rounded-full flex gap-1 sm:gap-2">
                    <FontAwesomeIcon icon={faStar} className="mt-1" />
                    VIP
                  </span>
                </div>

                <div className="text-center mt-3 sm:mt-4 mb-6 sm:mb-8">
                  <span className="block text-xl sm:text-2xl font-bold">
                    {bonusList[0].name}
                  </span>
                  <img
                    src={bonusList[0].data.logo}
                    alt="Logo"
                    className="hidden lg:block my-12 sm:my-14 py-12 sm:py-14 w-full rotate-90"
                  />

                  <span className="block text-sm sm:text-md mt-1 sm:mt-2">
                    {bonusList[0].data.text}
                  </span>
                </div>
                <a
                  href={`https://link.redirect-links.xyz/redirect/${bonusList[0].data.name}`}
                  className="mt-auto bg-red-600 text-white text-sm sm:text-base px-4 sm:px-6 py-2 rounded-full"
                >
                  GİRİŞ YAP
                </a>
              </>
            )}
          </aside>

          <main className="w-[90%] lg:w-4/6 mx-auto lg:mx-0">
          {/*
            <div className="relative overflow-hidden w-full rounded-full my-9 h-[20%]">
              <div
                className="flex transition-transform ease-out duration-1000"
                style={{ transform: `translateX(${-currentIndex * 100}%)` }}
              >
                {bonusList.map((slide, index) => (
                  <div className="w-full flex-shrink-0" key={index}>
                    <a href={slide.data.url}>
                      <img
                        src={slide.data.gif}
                        alt={`Slide ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                    </a>
                  </div>
                ))}
              </div>
              <div className="absolute inset-x-0 bottom-0 flex justify-center pb-2 z-10">
                {bonusList.map((_, index) => (
                  <div
                    key={index}
                    className={`h-2 w-2 mx-1 rounded-full cursor-pointer ${
                      currentIndex === index ? "bg-white" : "bg-gray-400"
                    }`}
                    onClick={() => goToSlide(index)}
                  ></div>
                ))}
              </div>
            </div>*/}
            <div className="h-56 sm:h-100 xl:h-80 2xl:h-96">
            <Carousel  pauseOnHover>
            {bonusList.map((slide, index) => (
                  <div className="w-full flex-shrink-0" key={index}>
                    <a href={slide.data.url}>
                      <img
                        src={slide.data.gif}
                        alt={`Slide ${index + 1}`}
                        className="w-full  sm:h-100"
                      />
                    </a>
                  </div>
                ))}
            </Carousel>
            </div>
            

            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-8">
              {siteList.map((site, index) => (
                <div
                  key={index}
                  className="bg-[#030712] p-4 text-white rounded-lg flex flex-col justify-between transform transition-transform duration-300 hover:scale-105 hover:shadow-neon"
                >
                  <a
                    href={`https://link.redirect-links.xyz/redirect/${site.siteName}`}
                    className="text-left flex flex-col h-full"
                  >
                    <div>
                      <img src={site.data.logo} alt="logo" />
                      <p className="mt-4">{site.data.name}</p>
                    </div>
                    <div className="mt-auto">
                      <p className="text-gray-500 mt-2">
                        {site.data.ornekdata}
                      </p>
                      <p className="w-full text-center bg-[#2dbcb4] my-5 py-3 rounded-full">
                        Giriş Yap
                      </p>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </main>

          <aside className="w-[95%] sm:w-[85%] lg:w-60 bg-gray-900 text-white flex flex-col items-center p-3 sm:p-4 rounded-xl sm:rounded-2xl mx-auto lg:mx-0 mb-6 sm:mb-8 lg:mb-0 lg:h-[80vh]">
            {bonusList[1] && (
              <>
                <div className="flex flex-col items-center mt-4 sm:mt-6 mb-6 sm:mb-8">
                  <img
                    src={bonusList[1].data.logo}
                    alt="Logo"
                    className="h-8 sm:h-10 mb-3 sm:mb-4"
                  />
                  <span className="bg-red-600 text-xs sm:text-sm px-2 py-1 rounded-full flex gap-1 sm:gap-2">
                    <FontAwesomeIcon icon={faStar} className="mt-1" />
                    VIP
                  </span>
                </div>

                <div className="text-center mt-3 sm:mt-4 mb-6 sm:mb-8">
                  <span className="block text-xl sm:text-2xl font-bold">
                    {bonusList[1].name}
                  </span>
                  <img
                    src={bonusList[1].data.logo}
                    alt="Logo"
                    className="hidden lg:block my-12 sm:my-14 py-12 sm:py-14 w-full rotate-90"
                  />

                  <span className="block text-sm sm:text-md mt-1 sm:mt-2">
                    {bonusList[1].data.text}
                  </span>
                </div>
                <a
                  href={`https://link.redirect-links.xyz/redirect/${bonusList[1].data.name}`}
                  className="mt-auto bg-red-600 text-white text-sm sm:text-base px-4 sm:px-6 py-2 rounded-full"
                >
                  GİRİŞ YAP
                </a>
              </>
            )}
          </aside>
        </div>
      </header>
      <footer className="bg-[#282c34] text-white text-center py-3">
        ® YILMAZVEGAS tüm hakları saklıdır.
      </footer>
    </div>
  );
}

export default App;
